import React from "react";
import classNames from "classnames";
import { NAV_LINKS_NAME } from "../../constants/common";
import { Link } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";

interface NavItemProps {
  item: { title: string; link: string };
  pathname: string;
  openModal: (title: string) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavItem: React.FC<NavItemProps> = ({
  item,
  pathname,
  openModal,
  setIsOpen,
}) => {
  const { isSignedIn } = useAuth();

  const handleClick = (e: React.MouseEvent) => {
    if (item.title === NAV_LINKS_NAME.CONTACT && !isSignedIn) {
      e.preventDefault();
      openModal(item.title);
    }
    setIsOpen(false);
  };

  return (
    <li key={item.title}>
      {!isSignedIn ? (
        <Link to={item.title === NAV_LINKS_NAME.CONTACT ? "#" : item.link}>
          <span
            className={classNames("relative inline-block", {
              "text-white font-bold": pathname === item.link,
            })}
            onClick={
              item.title === NAV_LINKS_NAME.CONTACT ? handleClick : undefined
            }
          >
            {item.title}
            <div
              className={classNames("h-[2px] bg-white", {
                hidden: pathname !== item.link,
              })}
            />
          </span>
        </Link>
      ) : (
        <Link to={item.link}>
          <span
            className={classNames("relative inline-block", {
              "text-white font-bold": pathname === item.link,
            })}
            onClick={handleClick}
          >
            {item.title}
            <div
              className={classNames("h-[2px] bg-white", {
                hidden: pathname !== item.link,
              })}
            />
          </span>
        </Link>
      )}
    </li>
  );
};

export default NavItem;
