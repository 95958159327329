import React, { Fragment } from "react";
import Typography from "../../components/Typography";
import contentJSON from "../../constants/content.json";
import Markdown from "react-markdown";

const Privacy: React.FC = () => {
  const content = contentJSON.privacy;

  return (
    <div className="py-20 px-4">
      <div className="mx-auto w-full 2xl:max-w-[1700px] pb-28">
        <Typography
          variant="heading"
          block
          className="mb-5 text-center text-secondary-blue"
        >
          {content.title}
        </Typography>
        <div className="container mx-auto">
          <Typography block className="mx-auto !font-semibold mt-6">
            {content.statement}
          </Typography>
          <Typography block className="mx-auto text-dark-gray">
            {content.lastUpdated}
          </Typography>
          <Markdown
            components={{
              a: ({ node, ...props }) => (
                <a
                  {...props}
                  className="text-primary-blue underline hover:text-secondary-blue"
                />
              ),
            }}
            className="text-dark-gray font-medium mt-2 whitespace-pre-line"
          >
            {content.description}
          </Markdown>
          <ol className="mt-6 space-y-10">
            {content.privacyPolicy.map((policy, index) => (
              <li key={index} className="text-dark-gray">
                <Typography className="!font-semibold text-xl" block>
                  {policy.heading}
                </Typography>
                <Typography className="mt-3" block>
                  {policy.description}
                </Typography>

                <ol className="mt-2 space-y-2">
                  {policy.content?.map((item, policyiIndex) => (
                    <Fragment key={policyiIndex}>
                      {policyiIndex === 1 ? (
                        <Typography className="mt-3" block>
                          {policy.description1}
                        </Typography>
                      ) : null}
                      <li className="text-black list-disc ml-8">
                        <Typography key={index} block>
                          <span className="!font-semibold">
                            {item.subheading}:{" "}
                          </span>
                          <span className="text-dark-gray">{item.details}</span>
                        </Typography>
                      </li>
                    </Fragment>
                  ))}
                </ol>
              </li>
            ))}
          </ol>

          <div className="container mt-6 mx-auto">
            <Typography className="!font-semibold text-xl" block>
              {content.contactUs.heading}
            </Typography>
            <Typography className="mt-3 text-dark-gray" block>
              {content.contactUs.details}
            </Typography>
          </div>
        </div>
      </div>
      <div className="border w-3/5 mx-auto" />
      <div className="mx-auto w-full 2xl:max-w-[1700px] pt-28" id="ccpaNotice">
        <Typography
          variant="heading"
          block
          className="mb-5 text-center text-secondary-blue"
        >
          {content.ccpaNotice.title}
        </Typography>
        <div className="container mx-auto">
          <Typography block className="mx-auto mt-6 text-dark-gray">
            {content.ccpaNotice.lastUpdated}
          </Typography>
          <Typography
            block
            className="mx-auto mt-4 text-dark-gray whitespace-pre-line"
          >
            {content.ccpaNotice.description}
          </Typography>
          <Typography className="!font-semibold text-xl mt-4" block>
            {content.ccpaNotice.rights.title}
          </Typography>
          <Typography className="mt-3 text-dark-gray" block>
            {content.ccpaNotice.rights.description}
          </Typography>
          <ol className="mt-6 space-y-10">
            {content.ccpaNotice.rights.rightsList.map((right, index) => (
              <li key={index} className="list-disc ml-6">
                <Typography className="text-dark-gray text-xl" block>
                  <span className="!font-semibold text-black">
                    {" "}
                    {right.right}:{" "}
                  </span>{" "}
                  {right.description}
                </Typography>
                <ol className="mt-2">
                  {right.details?.map((detail, index) => (
                    <li key={index} className="text-black list-disc ml-10">
                      <Typography className="text-dark-gray" block>
                        {detail}
                      </Typography>
                    </li>
                  ))}
                </ol>
              </li>
            ))}
          </ol>
          <div className="container mt-6 mx-auto">
            <Typography className="!font-semibold text-xl" block>
              {content.exerciseYourRights.heading}
            </Typography>
            <Typography className="mt-3 text-dark-gray" block>
              {content.exerciseYourRights.details}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
