import React, { useEffect, useState } from "react";
import Button from "../../Button";
import Typography from "../../Typography";
import contentJSON from "../../../constants/content.json";
import ArrowImage from "../../../assets/svgs/arrow.svg";
import Logo from "../../../assets/svgs/logo.svg";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { PARTICLES_OPTIONS } from "../../../constants/particlesOptions";

const HeroSection = () => {
  const content = contentJSON.home.heroSection;

  const navigateToInsights = () => {
    const insightsElement = document.getElementById("insights");
    if (insightsElement) {
      insightsElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  return (
    <div
      className="px-8 sm:px-20 my-auto relative pb-10 flex flex-col justify-center h-fit sm:h-[calc(100vh-92px)]"
      id="top"
    >
      <div className="flex flex-col gap-4 py-8 sm:pt-16 2xl:pt-32 sm:pb-12">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
          <img src={Logo} alt="Logo" className="size-[96px]" />
          <Typography
            variant="title"
            className="text-white font-workSans font-bold sm:font-extralight sm:!text-8xl 2xl:!text-9xl"
          >
            {content.title}
          </Typography>
        </div>
        <Typography
          variant="subheading"
          block
          className="font-workSans text-white !font-light !text-2xl 2xl:!text-4xl"
        >
          {content.subHeading}
        </Typography>
      </div>
      <div className="space-y-5 w-full sm:w-4/6">
        <Typography
          block
          className="xl:w-3/4 pt-3 pb-2 font-workSans text-white !font-light 2xl:text-4xl"
        >
          {content.description}
        </Typography>
        <Button
          variant="gradient"
          className="h-[52px] text-lg font-semibold font-workSans tracking-wide"
          onClick={navigateToInsights}
        >
          {content.btnText}
        </Button>
        <img src={ArrowImage} alt="arrowImage" className="pl-8" />
      </div>
      {init && (
        <Particles
          className="size-full absolute top-0 left-0 -z-10"
          options={PARTICLES_OPTIONS}
        />
      )}
    </div>
  );
};

export default HeroSection;
