import Bars from "../assets/svgs/bars.svg";
import contentJSON from "../constants/content.json";
import wvImageOne from "../assets/images/v1.webp";
import wvImageTwo from "../assets/images/v2.webp";
import wvImageThree from "../assets/images/v3.webp";
import wvImageFour from "../assets/images/v4.webp";
import MergeIcon from "../assets/svgs/mergeIcon.svg";
import FundInfoIcon from "../assets/svgs/fundInfo.svg";
import FundIdenIcon from "../assets/svgs/funIdentifiers.svg";
import BenchMarkIcon from "../assets/svgs/benchmark.svg";
import { ROUTES } from "../routes";

export enum NAV_LINKS_NAME {
  HOME = "Home",
  WEALTHVISTA_SOLUTION = "WealthVista Solutions",
  ABOUT = "About",
  CONTACT = "Contact Us",
  LOGIN = "Login",
  REQUEST_A_DEMO = "Request a Demo",
  TERMS_OF_USE = "Terms of Use",
  PRIVACY = "Privacy",
}

export const NAV_LINK = [
  {
    title: NAV_LINKS_NAME.HOME,
    link: ROUTES.HOME,
  },
  {
    title: NAV_LINKS_NAME.WEALTHVISTA_SOLUTION,
    link: ROUTES.WEALTH_VISTA_SOLUTION,
  },
  {
    title: NAV_LINKS_NAME.ABOUT,
    link: ROUTES.ABOUT,
  },
  {
    title: NAV_LINKS_NAME.CONTACT,
    link: ROUTES.CONTACT,
  },
  {
    title: NAV_LINKS_NAME.TERMS_OF_USE,
    link: ROUTES.TERMS_OF_USE,
  },
  {
    title: NAV_LINKS_NAME.PRIVACY,
    link: ROUTES.PRIVACY,
  },
];
export const DATA_FOR_BARS = [
  {
    heading: contentJSON.home.insightSection.passiveETFs,
    start: 0,
    end: 100,
  },
  {
    heading: contentJSON.home.insightSection.activeETFs,
    start: 0,
    end: 100,
  },
  {
    heading: contentJSON.home.insightSection.closeEndFunds,
    start: 0,
    end: 97,
  },
  {
    heading: contentJSON.home.insightSection.openEndMutualFunds,
    start: 0,
    end: 93,
  },
  {
    heading: contentJSON.home.insightSection.moneyMarketFunds,
    start: 0,
    end: 84,
  },
  {
    heading: contentJSON.home.insightSection.intervalFunds,
    start: 0,
    end: 82,
  },
];
export const PHONE_NUMBER = "(732) 290-5662";
export const EMAIL = "Max.Sparshatt@WealthVista.com";
export const CONTACT_EMAIL = "info@wealthvista.com";
export const WEALTH_VISTA_CARDS = [
  {
    title: contentJSON.wealthVistaSolution.cardText.firstCard,
    image: wvImageOne,
    url: 'https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaMutualFundDashboard/WealthVistaMutualFundDashboardDark?:embed=yes&:toolbar=no',
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.secondCard,
    image: wvImageTwo,

    url: 'https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaBrokerDealerDashboard/WealthVistaBrokerDealerDashboardDark?:embed=yes&:toolbar=no',
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.thiredCard,
    image: wvImageThree,
    url: 'https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaMutualFundDatabase/MutualFundDatabase?:embed=yes&:toolbar=no',
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.fourthCard,
    image: wvImageFour,
    url: 'https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaBrokerDealerDatabase/BrokerDealerDatabase?:embed=yes&:toolbar=no',
  },
];
export const COMPREHENSIVE_SECTION_IMAGES = [
  MergeIcon,
  Bars,
  BenchMarkIcon,
  FundInfoIcon,
  FundIdenIcon,
];
