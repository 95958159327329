import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NamedLogo from "../../assets/svgs/namedLogo.svg";
import { FaUserCircle } from "react-icons/fa";
import { signOut } from "aws-amplify/auth";
import toast from "react-hot-toast";

import contentJSON from "../../constants/content.json";

import { CgMenu } from "react-icons/cg";
import { useEffect, useState } from "react";
import NavItem from "./Navitem";
import { NAV_LINK, NAV_LINKS_NAME } from "../../constants/common";
import LoginModal from "../LoginModal";
import ContactModal from "../ContactModal";
import RequestDemoModal from "../RequestADemoModal";
import Button from "../Button";
import Typography from "../Typography";
import { useAuth } from "../../provider/AuthProvider";

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isSignedIn, setIsSignedIn, user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [navLink, setNavLink] = useState(
    NAV_LINK.filter(
      (item) =>
        item.title !== NAV_LINKS_NAME.TERMS_OF_USE &&
        item.title !== NAV_LINKS_NAME.PRIVACY
    )
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (title: string) => {
    setModalTitle(title);
    setModalOpen(true);
    setIsOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsOpen(false);
  };

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await signOut();
      localStorage.clear();
      navigate("/");
      setIsSignedIn(false);
    } catch (error) {
      toast.error((error as Error).message);
    }
    setLoading(false);
    toggleDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (isSignedIn) {
      const filteredLinks = NAV_LINK.filter(
        (item) =>
          item.title !== NAV_LINKS_NAME.HOME &&
          item.title !== NAV_LINKS_NAME.ABOUT &&
          item.title !== NAV_LINKS_NAME.TERMS_OF_USE &&
          item.title !== NAV_LINKS_NAME.PRIVACY
      );
      setNavLink(filteredLinks);
    } else {
      const filteredLinks = NAV_LINK.filter(
        (item) =>
          item.title !== NAV_LINKS_NAME.TERMS_OF_USE &&
          item.title !== NAV_LINKS_NAME.PRIVACY
      );
      setNavLink(filteredLinks);
    }
  }, [isSignedIn]);

  const content = contentJSON.header;

  return (
    <div className="bg-primary-blue" id="header">
      {modalOpen && (
        <>
          {modalTitle === NAV_LINKS_NAME.LOGIN ? (
            <LoginModal modalOpen={modalOpen} closeModal={closeModal} />
          ) : null}
          {modalTitle === NAV_LINKS_NAME.CONTACT && (
            <ContactModal modalOpen={modalOpen} closeModal={closeModal} />
          )}
          {modalTitle === NAV_LINKS_NAME.REQUEST_A_DEMO && (
            <RequestDemoModal modalOpen={modalOpen} closeModal={closeModal} />
          )}
        </>
      )}
      <nav className="mx-auto flex h-[92px] items-center justify-between gap-5 px-5 text-white md:px-14 xl:px-32 fixed z-40 w-full bg-primary-blue">
        <div className="1/3 lg:w-1/4">
          <img src={NamedLogo} alt="Logo" className="w-36 2xl:w-44" />
        </div>
        <ul className="hidden w-2/4 items-center justify-center gap-5 lg:flex xl:gap-10">
          {navLink.map((item) => (
            <NavItem
              key={item.title}
              item={item}
              pathname={pathname}
              openModal={openModal}
              setIsOpen={setIsOpen}
            />
          ))}
        </ul>
        <div className="w-2/3 flex items-center justify-end gap-5 lg:w-1/4 mr-5">
          {!isSignedIn ? (
            <>
              <Button
                onClick={() => openModal(NAV_LINKS_NAME.LOGIN)}
                className="h-auto !bg-transparent !p-0 text-xs text-white md:text-base"
              >
                {content.login}
              </Button>
              <Button
                onClick={() => openModal(NAV_LINKS_NAME.REQUEST_A_DEMO)}
                className="hidden px-2 text-xs font-medium sm:block md:px-3 md:text-base xl:px-5"
              >
                {content.requestDemo}
              </Button>
            </>
          ) : (
            <div className="flex w-full justify-end items-center gap-4">
              <Typography block className="text-white">
                {user.given_name}
              </Typography>
              <div ref={dropdownRef} className="relative">
                <FaUserCircle
                  className="text-base md:text-3xl"
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <Button
                    onClick={handleSignOut}
                    className="absolute mt-1 h-8 shadow-lg right-0 rounded-sm z-50"
                    loading={loading}
                  >
                    {content.logout}
                  </Button>
                )}
              </div>
            </div>
          )}
          <CgMenu
            color="white"
            size={20}
            className="lg:hidden"
            onClick={toggleIsOpen}
          />
        </div>
      </nav>
      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-40 bg-black opacity-70"
            onClick={toggleIsOpen}
          />
          <div className="fixed inset-y-0 left-0 z-50 w-[70%] transform bg-primary-blue text-white shadow-lg transition-transform duration-300 ease-in-out">
            <button
              onClick={toggleIsOpen}
              className="flex w-full justify-end px-4 pt-2 text-2xl"
            >
              &times;
            </button>
            <ul className="space-y-4 p-4">
              {navLink.map((item) => (
                <NavItem
                  key={item.title}
                  item={item}
                  pathname={pathname}
                  openModal={openModal}
                  setIsOpen={setIsOpen}
                />
              ))}
            </ul>
            {!isSignedIn ? (
              <Button
                onClick={() => openModal(NAV_LINKS_NAME.REQUEST_A_DEMO)}
                className="mx-4 block px-2 text-xs font-medium sm:hidden md:px-3 md:text-base xl:px-5"
              >
                {content.requestDemo}
              </Button>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
