import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

interface HeaderContextType {
  isHideHeader: boolean;
  setIsHideHeader: Dispatch<SetStateAction<boolean>>;
}

interface IHeaderProvider {
  children: React.ReactNode;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const HeaderProvider: React.FC<IHeaderProvider> = ({ children }) => {
  const [isHideHeader, setIsHideHeader] = useState<boolean>(false);

  return (
    <HeaderContext.Provider value={{ isHideHeader, setIsHideHeader }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = (): HeaderContextType => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeader must be used within an HeaderProvider");
  }
  return context;
};
