import React from "react";
import contentJSON from "../../constants/content.json";
import AboutImageOne from "../../assets/images/aboutOne.webp";
import AboutImageTwo from "../../assets/images/aboutTwo.webp";
import AboutSectionCard from "./AboutSectionCard";

const About = () => {
  const content = contentJSON.home.about;
  return (
    <div className="relative bg-white pt-[103px] pb-24" id="about">
      <div className="absolute inset-0 z-0 bg-lines-pattern bg-cover opacity-30"></div>
      <div className="relative mx-auto 2xl:w-[1700px]">
        <AboutSectionCard
          title={content.titleOne}
          desc={content.textOne}
          image={AboutImageOne}
        />
        <AboutSectionCard
          title={content.titleTwo}
          desc={content.textTwo}
          image={AboutImageTwo}
          className="mt-24"
          secondDiv
        />
      </div>
    </div>
  );
};

export default About;
