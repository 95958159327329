import React, { FC } from "react";
import Typography from "../../Typography";

interface IStrategicSolutionsCard {
  title: string;
  desc: string;
}
const StrategicSolutionsCard: FC<IStrategicSolutionsCard> = ({
  title,
  desc,
}) => {
  return (
    <div className="flex w-auto flex-col justify-end lg:w-[340px]">
      <Typography
        block
        className="mb-1 text-center text-xs !font-semibold text-secondary-blue sm:mb-3 sm:text-lg"
      >
        {title}
      </Typography>
      <Typography
        block
        className="text-center text-xs text-dark-gray sm:text-base"
      >
        {desc}
      </Typography>
    </div>
  );
};
export default StrategicSolutionsCard;
