import React, { FC } from "react";
import classNames from "classnames";
import Typography from "../../components/Typography";

interface ISectionCard {
  image: string;
  title: string;
  desc: string;
  className?: string;
  sectionClassName?: string;
  secondDiv?: boolean;
}

const AboutSectionCard: FC<ISectionCard> = ({
  title,
  desc,
  image,
  className,
  sectionClassName,
  secondDiv,
}) => {
  return (
    <div className={classNames("mx-auto w-10/12 md:w-3/4", className)}>
      <Typography
        variant="heading"
        className="w-full text-center text-secondary-blue"
      >
        {title}
      </Typography>
      <div
        className={classNames(
          "z-30 mt-11 flex h-full flex-col items-center rounded-xl bg-white shadow-lg xl:flex-row overflow-hidden ",
          {
            "lg:flex-row-reverse": secondDiv,
          },
          sectionClassName
        )}
      >
        <div
          className={classNames(
            "flex min-h-full w-full xl:w-[45%] xl:rounded-r-none",
            {
              "xl:w-[55%] xl:rounded-l-none": secondDiv,
            }
          )}
        >
          <img alt={title} src={image} className="h-full w-full object-cover" />
        </div>
        <Typography
          className={classNames("py-6 px-4 md:px-8 text-[17px] xl:w-[55%]", {
            "xl:w-[45%]": secondDiv,
          })}
        >
          {desc}
        </Typography>
      </div>
    </div>
  );
};
export default AboutSectionCard;
