import { fetchUserAttributes, signOut } from "aws-amplify/auth";
import React, { createContext, useState, useEffect, useContext } from "react";

interface AuthContextType {
  isSignedIn: boolean;
  setIsSignedIn: (isSignedIn: boolean) => void;
  user: any;
}

interface IAuthProvider {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [user, setUser] = useState({});

  const handleLogout = () => {
    signOut();
    localStorage.clear();
  };

  useEffect(() => {
    const signedInStatus = localStorage.getItem("isSignedIn") === "true";
    setIsSignedIn(signedInStatus);
    const handleTabClose = (event: any) => {
      event.preventDefault();
      event.returnValue = "";

      handleLogout();
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const getUser = async (): Promise<void> => {
    try {
      const currentUserAttributes = await fetchUserAttributes();

      setUser(currentUserAttributes);
    } catch (error) {
      console.log("Error in getting user", error);
    }
  };

  useEffect(() => {
    getUser();
  }, [isSignedIn]);

  return (
    <AuthContext.Provider value={{ isSignedIn, setIsSignedIn, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
