import React from "react";
import Typography from "../../Typography";
import contentJSON from "../../../constants/content.json";
import DotsImageBottom from "../../../assets/svgs/dotsBottom.svg";

import SectionImage from "../../../assets/images/comprehensiveDataRepositoriesImage.webp";

import ImageWithTextSection from "../../ImageWithTextSection";
import { COMPREHENSIVE_SECTION_IMAGES } from "../../../constants/common";

const ComprehensiveDataRepositories = () => {
  const content = contentJSON.home.comprehensiveDataRepositories;
  return (
    <div className="relative overflow-hidden bg-dull-white py-20">
      <div className="mx-auto 2xl:w-[1700px]">
        <img
          src={DotsImageBottom}
          alt="dotsTop"
          className="absolute top-[-80px] left-[-50px]"
        />
        <img
          src={DotsImageBottom}
          alt="dotsTop"
          className="absolute bottom-[-80px] right-[-40px]"
        />
        <div className="relative mx-auto w-10/12 text-center md:w-1/2">
          <Typography
            variant="heading"
            block
            className="mb-8 text-secondary-blue"
          >
            {content.title}
          </Typography>
          <div className="space-y-5">
            {content.description.map((item: string) => (
              <Typography
                block
                className="!font-normal text-dark-gray"
                key={item}
              >
                {item}
              </Typography>
            ))}
          </div>
        </div>
        <div className="mx-auto mt-7 w-11/12 lg:w-3/4 2xl:w-10/12">
          <img
            src={SectionImage}
            alt="Comprehensive Data Repositories Image"
            className="mb-[70px] rounded-3xl shadow-xl"
          />
          <div className="grid grid-cols-1 gap-x-10 lg:grid-cols-2">
            {content.columnText.map((text, index) => (
              <ImageWithTextSection
                key={index}
                icon={COMPREHENSIVE_SECTION_IMAGES[index]}
                data={text}
                className="mb-14"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComprehensiveDataRepositories;
