import React from "react";
import { RxCross2 } from "react-icons/rx";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-40">
          <div
            className="mx-3 w-full rounded-2xl bg-white py-6 px-3 shadow-lg sm:w-10/12 sm:px-6 md:w-[469px] lg:mx-0"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            onKeyDown={handleKeyDown}
          >
            <div className="flex justify-end">
              <button
                className="text-secondary-blue hover:text-primary-blue focus:outline-none"
                onClick={onClose}
                aria-label="Close modal"
              >
                <RxCross2 />
              </button>
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
