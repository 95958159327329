import { DATA_FOR_BARS } from "../../../constants/common";
import Typography from "../../Typography";
import React, { useEffect, useRef, useState } from "react";
import contentJSON from "../../../constants/content.json";
import InsightIconOne from "../../../assets/svgs/InsightIconOne.svg";
import InsightIconTwo from "../../../assets/svgs/InsightIconTwo.svg";
import InsightIconThree from "../../../assets/svgs/InsightIconThree.svg";
import ImageWithTextSection from "../../ImageWithTextSection";

const InsightSection = () => {
  const content = contentJSON.home.insightSection;
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="relative bg-white pt-24 pb-24" id="insights">
      <div className="absolute inset-0 bg-lines-pattern bg-cover opacity-30"></div>
      <div className="mx-auto 2xl:max-w-[1700px]">
        <div className="relative mx-auto w-10/12 mb-16 text-center md:w-1/2">
          <Typography
            variant="subheading"
            block
            className="mb-8 !font-medium text-secondary-blue"
          >
            {content.title}
          </Typography>
        </div>
        <div className="my-10 lg:my-16 justify-center w-11/12 md:w-3/4 font-workSans font-light text-4xl mx-auto text-center">
          {content.subHeading[0]}
          <span className="text-secondary-blue">{content.trillion}</span>
          {content.subHeading[1]}
          <span className="text-secondary-blue">{content.capturing}</span>
          {content.subHeading[2]}
        </div>
        <div className="relative mx-auto flex w-11/12 md:w-10/12 flex-col gap-14 lg:flex-row">
          <div className="flex flex-col justify-between lg:w-1/2">
            {DATA_FOR_BARS.map((bar, index) => (
              <div className="flex items-center gap-3 h-10" key={index}>
                <Typography className="w-2/5 text-sm md:text-base">
                  {bar.heading}
                </Typography>
                <div className="flex w-3/5 items-center gap-2">
                  <div className="flex-1">
                    <div className="h-5 overflow-hidden rounded-md bg-gray-200 dark:bg-gray-700">
                      <div
                        className={`duration-1000 h-5 bg-gradient-to-r from-primary-blue to-secondary-blue transition-all ease-in-out ${
                          isVisible ? "w-full" : "w-0"
                        }`}
                        style={{ width: `${isVisible ? bar.end : 0}%` }}
                      />
                    </div>
                  </div>
                  <span className="w-10 text-right">{bar.end}%</span>
                </div>
              </div>
            ))}
          </div>
          <div className="space-y-11 lg:w-1/2">
            <ImageWithTextSection
              icon={InsightIconOne}
              data={content.sideOption[0]}
            />
            <ImageWithTextSection
              icon={InsightIconTwo}
              data={content.sideOption[1]}
            />
            <ImageWithTextSection
              icon={InsightIconThree}
              data={content.sideOption[2]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightSection;
