import React from "react";
import { Amplify } from "aws-amplify";
import { defaultStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./pages/Home";

import aws_exports from "./aws-exports";
import WealthVistaSolution from "./pages/WealthVistaSolution";
import Contact from "./pages/Contact";
import About from "./pages/About";
import { ROUTES } from "./routes";
import { useAuth } from "./provider/AuthProvider";
import TermsOfUse from "./pages/TermsOfUse";
import Privacy from "./pages/Privacy";
import { useHeader } from "./provider/HeaderProvider";
import { cn } from "./utils/common";

Amplify.configure(aws_exports);
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

export function App() {
  const { isSignedIn } = useAuth();
  const { isHideHeader } = useHeader();
  return (
    <Router>
      <div className="flex min-h-screen flex-col justify-between">
        <Toaster />
        {!isHideHeader ? <Header /> : null}
        <div
          className={cn({
            "mt-[92px]": !isHideHeader,
          })}
        >
          <Routes>
            <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.ABOUT} element={<About />} />
            <Route
              path={ROUTES.WEALTH_VISTA_SOLUTION}
              element={<WealthVistaSolution />}
            />
            <Route path={ROUTES.CONTACT} element={<Contact />} />
            <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
            <Route path={ROUTES.PRIVACY} element={<Privacy />} />
          </Routes>
        </div>
        {!isSignedIn ? <Footer /> : null}
      </div>
    </Router>
  );
}

export default App;
