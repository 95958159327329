import React from "react";
import Modal from "./Modal";
import Typography from "./Typography";
import { CONTACT_EMAIL } from "../constants/common";
import contentJSON from "../constants/content.json";

interface IContactModal {
  modalOpen: boolean;
  closeModal: () => void;
}

const ContactModal: React.FC<IContactModal> = ({ modalOpen, closeModal }) => {
  const content = contentJSON.contactModal;

  return (
    <Modal isOpen={modalOpen} onClose={closeModal}>
      <div className="pb-8">
        <Typography
          variant="heading"
          block
          className="mb-3 text-center font-medium text-secondary-blue"
        >
          {content.title}
        </Typography>
        <Typography
          block
          className="mb-4 px-3 text-center !text-base font-normal text-dark-gray"
        >
          {content.description}
        </Typography>
        <Typography
          block
          className="text-center font-normal tracking-wide text-secondary-blue underline"
        >
          {CONTACT_EMAIL}
        </Typography>
      </div>
    </Modal>
  );
};

export default ContactModal;
