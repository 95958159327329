import React from "react";
import HeroSection from "../../components/Home/HeroSection";
import InsightSection from "../../components/Home/InsightsSection";

const Home: React.FC = () => {
  return (
    <div>
      <HeroSection />
      <InsightSection />
    </div>
  );
};

export default Home;
