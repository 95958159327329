import React, { useState } from "react";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import Typography from "../../components/Typography";
import { EMAIL, PHONE_NUMBER } from "../../constants/common";
import contentJSON from "../../constants/content.json";
import toast from "react-hot-toast";
import { useAuth } from "../../provider/AuthProvider";

const Contact: React.FC = () => {
  const content = contentJSON.contact;
  const { user } = useAuth();

  const [inquiry, setInquiry] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!inquiry) {
      return;
    }
    try {
      setLoading(true);
      const data = {
        date: new Date().toLocaleDateString("en-US"),
        id: user.email,
        inquiry,
      };
      const appScriptWeb =
        process.env.REACT_APP_GOOGLE_APP_SCRIPT_WEB_CONTACT || "";
      await fetch(appScriptWeb, {
        redirect: "follow",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      });
      toast.success("Request submitted successfully");
    } catch (error) {
      toast.error((error as Error).message);
      console.error("Error:", error);
    }
    setInquiry("");
    setLoading(false);
  };

  return (
    <div className="relative h-full bg-white py-10 md:pt-20">
      <div className="absolute inset-0 bg-lines-pattern bg-cover opacity-30" />
      <div className="relative mx-auto w-11/12 lg:w-1/2 2xl:max-w-[1700px]">
        <Typography
          variant="heading"
          block
          className="mb-6 text-center text-secondary-blue md:mb-10"
        >
          {content.title}
        </Typography>
        <div className="space-y-7">
          {content.description.map((item, index) => (
            <Typography
              key={index}
              block
              className="text-center text-dark-gray"
            >
              {item}
            </Typography>
          ))}
          <Typography block className="text-center text-dark-gray">
            {EMAIL}
            <span className="block">{PHONE_NUMBER}</span>
          </Typography>
          <Typography block className="text-center text-dark-gray">
            {content.lookForward}
          </Typography>
          <div className="mx-auto w-2/3">
            <TextArea
              rows={4}
              textAreaClassName="h-56"
              value={inquiry}
              onChange={(event) => setInquiry(event.target.value)}
            />
          </div>
          <div className="flex justify-center" onClick={handleSubmit}>
            <Button variant="gradient" loading={loading}>
              {content.btnText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
