import React from "react";
import Typography from "../../Typography";
import TimerIcon from "../../../assets/svgs/timer.svg";
import ArrowImage from "../../../assets/svgs/arrow.svg";
import MessageIcon from "../../../assets/svgs/message.svg";
import SettingIcon from "../../../assets/svgs/setting.svg";
import contentJSON from "../../../constants/content.json";
import ImageWithTextSection from "../../ImageWithTextSection";
import SectionImage from "../../../assets/images/powerfulVisualizationDashboards.webp";

const PowerfulVisualizationDashboardsSection = () => {
  const content = contentJSON.home.powerfulVisualizationDashboardsSection;
  return (
    <div className="relative mx-auto bg-white pt-[103px] pb-24 2xl:w-[1700px]">
      <div className="absolute inset-0 bg-lines-pattern bg-cover opacity-30"></div>
      <div className="relative mx-auto w-10/12 text-center md:w-1/2">
        <Typography
          variant="heading"
          block
          className="mb-8 text-secondary-blue"
        >
          {content.title}
        </Typography>
        <div className="space-y-5">
          {content.description.map((item: string) => (
            <Typography
              block
              className="!font-normal text-dark-gray"
              key={item}
            >
              {item}
            </Typography>
          ))}
        </div>
      </div>
      <div className="relative mx-auto mt-11 flex w-10/12 flex-col items-center gap-14 lg:flex-row lg:items-start">
        <div className="relative lg:w-1/2">
          <div className="relative inline-block">
            <img
              src={SectionImage}
              alt="Section Image"
              className="rounded-3xl"
            />
            <img
              src={ArrowImage}
              alt="dotsTop"
              className="absolute right-[-20px] bottom-[-100px] rotate-[-20deg]"
            />
          </div>
        </div>
        <div className="space-y-11 lg:w-1/2">
          <ImageWithTextSection
            icon={MessageIcon}
            data={content.columnText[0]}
          />
          <ImageWithTextSection icon={TimerIcon} data={content.columnText[1]} />
          <ImageWithTextSection
            icon={SettingIcon}
            data={content.columnText[2]}
          />
        </div>
      </div>
    </div>
  );
};

export default PowerfulVisualizationDashboardsSection;
