import React, { useState } from "react";
import Typography from "../../components/Typography";
import { WEALTH_VISTA_CARDS } from "../../constants/common";
import StrategicSolutions from "../../components/Home/StrategicSolutions";
import PowerfulVisualizationDashboardsSection from "../../components/Home/PowerfulVisualizationDashboardsSection";
import ComprehensiveDataRepositories from "../../components/Home/ComprehensiveDataRepositories";
import { useAuth } from "../../provider/AuthProvider";
import Tableau from "../../components/tableau/Tableau";
import { cn } from "../../utils/common";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useHeader } from "../../provider/HeaderProvider";

const WealthVistaSolution: React.FC = () => {
  const { isSignedIn } = useAuth();
  const { setIsHideHeader } = useHeader();
  const [tableauURL, setTableauURL] = useState("");
  const [hideCards, setHideCards] = useState(false);

  const handleSidebarClose = () => {
    setIsHideHeader(false);
    setHideCards(true);
    setTimeout(() => {
      setTableauURL("");
      setHideCards(false);
    }, 1000);
  };

  if (!isSignedIn)
    return (
      <div>
        <StrategicSolutions />
        <PowerfulVisualizationDashboardsSection />
        <ComprehensiveDataRepositories />
      </div>
    );
  return (
    <>
      {tableauURL ? (
        <>
          <div
            className={cn(
              "p-1 font-bold w-full flex cursor-pointer items-end align-left justify-end pr-4 bg-cyan-700/20",
              { hidden: hideCards }
            )}
            onClick={handleSidebarClose}
          >
            <RiArrowGoBackLine size={24} />
          </div>
          <Tableau url={tableauURL} hideCards={hideCards} />
        </>
      ) : null}
      <div
        className={cn("relative min-h-screen pt-10 md:py-20", {
          hidden: tableauURL,
        })}
      >
        <div className="absolute inset-0 bg-lines-pattern bg-cover opacity-30"></div>
        <div className="md:1/2 relative mx-auto w-10/12 sm:w-1/2 lg:w-9/12">
          <div className="2xl:mx-auto 2xl:max-w-[1700px]">
            <div className="mx-auto grid w-full grid-cols-1 gap-x-10 gap-y-14 lg:grid-cols-2 xl:gap-x-24 2xl:w-2/3">
              {WEALTH_VISTA_CARDS.map((item, index) => (
                <div
                  key={index}
                  className="overflow-hidden rounded-2xl bg-white shadow-xl hover:drop-shadow-2xl transform transition-transform duration-300 hover:scale-105 cursor-pointer"
                  onClick={() => {
                    setIsHideHeader(true);
                    setTableauURL(item.url);
                  }}
                >
                  <img
                    src={item.image}
                    alt="Logo"
                    className="h-60 w-full rounded-t-2xl md:h-[300px]"
                  />
                  <Typography
                    block
                    className="mt-5 mb-4 text-center text-dark-gray"
                  >
                    {item.title}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WealthVistaSolution;
