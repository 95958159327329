import React from "react";
import Typography from "../../Typography";
import cycle from "../../../assets/images/cycle.webp";
import contentJSON from "../../../constants/content.json";
import cycleVertical from "../../../assets/images/cycleVertical.webp";
import DotsImageBottom from "../../../assets/svgs/dotsBottom.svg";
import StrategicSolutionsCard from "./StrategicSolutionsCard";

const StrategicSolutions = () => {
  const content = contentJSON.home.strategicSolutionSection;
  return (
    <div className="relative overflow-hidden bg-dull-white py-20">
      <img
        src={DotsImageBottom}
        alt="dotsTop"
        className="absolute top-[-80px] left-[-50px]"
      />
      <img
        src={DotsImageBottom}
        alt="dotsTop"
        className="absolute bottom-[-80px] right-[-40px]"
      />
      <div className="mx-auto w-full 2xl:max-w-[1700px]">
        <Typography
          variant="heading"
          block
          className="mb-5 text-center text-secondary-blue"
        >
          {content.title}
        </Typography>
        <Typography
          block
          className="mx-auto mb-16 w-10/12 text-center text-dark-gray md:w-1/2"
        >
          {content.description}
        </Typography>
        <div className="mx-auto flex w-11/12 flex-row lg:flex-col xl:w-3/4">
          <div className="order-3 my-20 flex w-1/3 flex-col justify-between gap-10  lg:order-1 lg:my-0 lg:w-auto lg:flex-row lg:items-start">
            {content.columnText.map((item, index) => (
              <StrategicSolutionsCard
                key={index}
                title={item.heading}
                desc={item.desc}
              />
            ))}
          </div>
          <div className="order-2 w-1/3 lg:my-7 lg:w-auto">
            <img src={cycle} alt="circles" className="hidden w-full lg:block" />
            <img
              src={cycleVertical}
              alt="circles"
              className="block  w-full lg:hidden"
            />
          </div>
          <div className="order-1 my-auto mx-auto flex h-2/3 w-1/3 flex-col justify-between gap-32 md:h-[550px] lg:order-3 lg:h-auto lg:w-3/5 lg:flex-row ">
            {content.belowImageColumnText.map((item, index) => (
              <StrategicSolutionsCard
                key={index}
                title={item.heading}
                desc={item.desc}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategicSolutions;
