import React from "react";
import Typography from "../../components/Typography";
import contentJSON from "../../constants/content.json";
import Markdown from "react-markdown";

const TermsOfUse: React.FC = () => {
  const content = contentJSON.termsOfUse;

  return (
    <div className="py-20 px-4">
      <div className="mx-auto w-full 2xl:max-w-[1700px] pb-24">
        <Typography
          variant="heading"
          block
          className="mb-5 text-center text-secondary-blue"
        >
          {content.title}
        </Typography>
        <div className="container mx-auto">
          <Typography block className="mx-auto mt-6 text-dark-gray">
            {content.lastUpdated}
          </Typography>
          <Typography
            block
            className="mx-auto mt-4 text-dark-gray whitespace-pre-line"
          >
            {content.description}
          </Typography>
          <ol className="list-decimal mt-4 space-y-4">
            {content.terms.map((term, index) => (
              <li key={index} className="!font-semibold ml-4 space-y-1">
                <Typography className="!font-semibold">{term.title}</Typography>
                <Markdown
                  components={{
                    a: ({ node, ...props }) => (
                      <a
                        {...props}
                        className="text-primary-blue underline hover:text-secondary-blue"
                      />
                    ),
                  }}
                  className="text-dark-gray font-medium mt-2 whitespace-pre-line"
                >
                  {term.content}
                </Markdown>
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="border w-3/5 mx-auto" />
      <div
        className="mx-auto w-full 2xl:max-w-[1700px] pt-24"
        id="social-media-disclaimer"
      >
        <Typography
          variant="heading"
          block
          className="mb-5 text-center text-secondary-blue"
        >
          {content.socialMediaDisclaimer.title}
        </Typography>
        <div className="container mx-auto">
          <Typography block className="mx-auto mt-6 text-dark-gray">
            {content.socialMediaDisclaimer.lastUpdated}
          </Typography>
          <Typography
            block
            className="mx-auto mt-4 text-dark-gray whitespace-pre-line"
          >
            {content.socialMediaDisclaimer.description}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
