import classNames from "classnames";
import React, { TextareaHTMLAttributes } from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  textAreaClassName?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  textAreaClassName,
  label,
  ...rest
}) => {
  return (
    <div className="mb-4">
      {label ? (
        <label
          htmlFor="message"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
        </label>
      ) : null}
      <textarea
        id="message"
        {...rest}
        className={classNames(
          "block w-full rounded-lg border border-gray-300 bg-white  p-2.5 text-sm text-gray-900 focus:border-primary-blue focus:ring-primary-blue dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-blue dark:focus:ring-primary-blue",
          textAreaClassName
        )}
      />
    </div>
  );
};

export default TextArea;
