import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa6";
import Logo from "../../assets/svgs/logoBlue.svg";
import contentJSON from "../../constants/content.json";
import { NAV_LINK, NAV_LINKS_NAME } from "../../constants/common";
import ContactModal from "../ContactModal";
import { useAuth } from "../../provider/AuthProvider";

const Footer: React.FC = () => {
  const content = contentJSON.footer;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const { isSignedIn } = useAuth();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const arrowUpButton = document.getElementById("arrowUpButton");
    arrowUpButton?.addEventListener("click", scrollToTop);

    return () => {
      arrowUpButton?.removeEventListener("click", scrollToTop);
    };
  }, []);

  const openModal = (title: string) => {
    setModalTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="bg-dr-white pb-5">
      <div className="mx-auto flex w-10/12 flex-col items-center gap-4 md:gap-8 py-2 lg:flex-row lg:py-4 xl:gap-10">
        <div className="md:w-3/12">
          <img src={Logo} alt="Logo" className="w-36 md:w-full 2xl:w-72" />
        </div>
        <div className="flex w-full flex-col items-center justify-between lg:w-10/12 lg:flex-row">
          <ul className="flex flex-col items-center gap-2 lg:flex-row lg:gap-8 xl:gap-8">
            {NAV_LINK.map((item) =>
              !isSignedIn ? (
                <Link
                  to={item.title === NAV_LINKS_NAME.CONTACT ? "#" : item.link}
                  key={item.title}
                  onClick={() => {
                    item.title === NAV_LINKS_NAME.CONTACT
                      ? openModal(item.title)
                      : scrollToTop();
                  }}
                >
                  {item.title}
                </Link>
              ) : (
                <li key={item.title}>
                  <Link to={item.link}>{item.title}</Link>
                </li>
              )
            )}
          </ul>
          <div
            id="arrowUpButton"
            className="mx-10 flex h-11 w-11 cursor-pointer items-center justify-center self-end rounded-full bg-secondary-blue"
          >
            <FaArrowUp color="#fff" />
          </div>
        </div>
      </div>
      <div className="text-center text-dark-gray">
        © {new Date().getFullYear()} {content.copyright}
      </div>
      {modalOpen && modalTitle === NAV_LINKS_NAME.CONTACT && (
        <ContactModal modalOpen={modalOpen} closeModal={closeModal} />
      )}
    </div>
  );
};

export default Footer;
