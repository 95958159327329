import React from "react";
import classNames from "classnames";
import Typography from "./Typography";

interface ISideSection {
  icon: string;
  data?: {
    title: string;
    desc?: string;
  };
  className?: string;
}

const ImageWithTextSection: React.FC<ISideSection> = ({
  data,
  icon,
  className,
}) => {
  return (
    <div className="flex gap-4">
      <div
        className={classNames(
          "flex h-16 min-w-[68px] items-center justify-center rounded-lg bg-white shadow-lg",
          className
        )}
      >
        <img src={icon} alt={data?.title || ""} width={32} height={32} />
      </div>
      <div>
        <Typography block className="!font-semibold text-secondary-blue">
          {data?.title}
        </Typography>
        <Typography
          block
          className="text-sm !font-normal leading-5 text-dark-gray"
        >
          {data?.desc}
        </Typography>
      </div>
    </div>
  );
};
export default ImageWithTextSection;
