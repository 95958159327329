import React, { FC, useEffect } from "react";
import { useAuth } from "../../provider/AuthProvider";
import StrategicSolutions from "../../components/Home/StrategicSolutions";
import PowerfulVisualizationDashboardsSection from "../../components/Home/PowerfulVisualizationDashboardsSection";
import ComprehensiveDataRepositories from "../../components/Home/ComprehensiveDataRepositories";

import { TableauViz } from "https://prod-useast-b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
import { cn } from "../../utils/common";

import * as jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';


interface TableauProps {
  url: string | null;
  hideCards: boolean;
}

const Tableau: FC<TableauProps> = (props) => {
  const { isSignedIn, user } = useAuth();
  const isSmallScreen = window.innerWidth < 768;

  useEffect(() => {
    const container = document.getElementById("tableauViz");
    if (container != null) {
      container.appendChild(viz);
    }
  }, []);

  // API - https://help.tableau.com/current/api/embedding_api/en-us/reference/interfaces/
  const viz = new TableauViz();

  viz.src = props.url;
  viz.toolbar = "hidden";
  viz.device = "desktop";
  viz.hideEditInDesktopButton = true;
  viz.suppressDefaultEditBehavior = true;

   // required for connected apps
    // https://help.tableau.com/current/online/en-us/connected_apps_direct.htm
    const secret = process.env.REACT_APP_TABLEAU_SECRET || "";
    const kid = process.env.REACT_APP_TABLEAU_KID || "";
    const clientId = process.env.REACT_APP_TABLEAU_CLIENT_ID || "";

  if (isSignedIn)
  {
    const current_time = Math.floor(Date.now() / 1000);
    const expiration_time = current_time + 300; // 5min
    const private_key = secret;
    const claims = {
        'iss': clientId,
        'exp': expiration_time,
        'aud': "tableau",
        'sub': user.email,
        'jti': uuidv4(),
        'scp': ["tableau:views:embed", "tableau:metrics:embed"],
        'https://tableau.com/groups': [user['custom:company']]
    };

  
    const jwt_token = jwt.sign(claims, private_key, { algorithm: 'HS256', keyid: kid });

    viz.token = jwt_token;
  }

  
  /*  Methods */
  if (!isSignedIn)
    return (
      <div>
        <StrategicSolutions />
        <PowerfulVisualizationDashboardsSection />
        <ComprehensiveDataRepositories />
      </div>
    );
  return (
    <div
      id="tableauViz"
      className={cn(
        "flex justify-center items-center min-h-[calc(100vh-32px)] transition-all duration-300 ease-in-out bg-cyan-700/20",
        {
          "slide-in-right": !isSmallScreen,
          "slide-out-right": !isSmallScreen && props.hideCards,
        }
      )}
    ></div>
  );
};
export default Tableau;
